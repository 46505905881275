import React from 'react';
import './hero.css';

const Hero = () => {
  return (
    <section>
      <div className="hero">
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="/images/AWS associate certificate.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://aws.amazon.com/verification/" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/AWS professional certificate.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://aws.amazon.com/verification/" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure administrator associate.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure fundamentals.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure network engineer associate.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure security engineer associate.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure AI Engineer Associate.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure solutions architect expert.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Azure Database Administrator.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://learn.microsoft.com/en-us/users/chichongfong-3416/transcript/dlp2awqem605p0v" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Cisco_CCDA_Certification.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://cp.certmetrics.com/cisco/en/public/verify/credential" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Cisco_CCDP_Certification.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://cp.certmetrics.com/cisco/en/public/verify/credential" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Cisco_CCNA_Certification.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://cp.certmetrics.com/cisco/en/public/verify/credential" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
            <div className="carousel-item">
              <img src="/images/Cisco_CCNP_Certification.jpg" className="d-block w-100" alt="..." />
              <div className="carousel-caption">
                <a href="https://cp.certmetrics.com/cisco/en/public/verify/credential" target="_blank" rel="noopener noreferrer">
                  <button>點擊驗證</button>
                </a>
              </div>
            </div>
          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;