import React from 'react';
import './header.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser, faBriefcase, faLaptop, faProjectDiagram, faFile } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/" style={{ color: '#333' }}>
          <FontAwesomeIcon icon={faFile} size="lg" /> 方志莊履歷 (2025年版)
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link
                className="nav-link active"
                to="/"
                style={{ color: '#333' }}
                aria-current="page"
              >
                <FontAwesomeIcon icon={faHome} size="lg" /> 主頁
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/about"
                style={{ color: '#333' }}
              >
                <FontAwesomeIcon icon={faUser} size="lg" /> 簡介
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/experience"
                style={{ color: '#333' }}
              >
                <FontAwesomeIcon icon={faBriefcase} size="lg" /> 經歷
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/skills"
                style={{ color: '#333' }}
              >
                <FontAwesomeIcon icon={faLaptop} size="lg" /> 技能
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/projects"
                style={{ color: '#333' }}
              >
                <FontAwesomeIcon icon={faProjectDiagram} size="lg" /> 專案
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="header-content">
        <div className="profile-picture">
          <img src="/images/方志莊.jpg" alt="方志莊" />
        </div>
        <h1>方志莊</h1>
        <p>2017年畢業於密德薩斯大學電腦網絡系(一等榮譽)，</p>
        <p>曾在多間大型公司工作，擁有資料科技相關專業知識，具八年工作經驗，專注於雲端開發和管理服務，並緊跟市場的產品和工具。</p>
        <p>我更喜歡接觸新鮮事物，例如人工智慧(AI)，並可能投入時間研究它們。</p>
        <a
          href="mailto:chichong2010@gmail.com?subject=Contact%20from%20Website&body=Hello%2C%20I%27m%20interested%20in%20getting%20in%20touch."
          className="get-in-touch-btn"
        >
          聯絡我
        </a>
      </div>
    </header>
  );
};

export default Header;